.arrowButton
    cursor: pointer
    border-radius: 999px
    padding: 15px 25px
    width: fit-content
    padding: 14px 28px
    &:hover
        background-color: #F1EFEF

    .arrowImage
        // Empty