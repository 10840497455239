.page
    margin-top: 55px
    
.servicesTitle
    font-size: 100px
    color: #535353
    p
        margin-bottom: 0rem
    


@media screen and (max-width: 768px ) 
    .servicesTitle
        font-size: 43px
        margin-left: 10px
        



.experienceBG
   background-color: rgba(244, 244, 244, 1)
   padding-top: 5rem
   padding-bottom: 5rem
   
   

.introText
    font-size: 22px
   

.experienceText
    font-size: 22px
    
    

@media screen and (max-width: 768px)
    .introText, .experienceText
        font-size: 16px

.introBG
    padding-top: 5rem
    padding-bottom: 5rem


.ohtitle2
        padding-top: 220px
        padding-bottom: 100px
        font-size: 50px

@media screen and (max-width: 768px )
    .ohtitle2
        font-size: 40px
        
.modules
    margin-bottom: 100px        
        
.modules       
        h2
            padding-bottom: 2rem
            font-size: 40px
        p
             padding-bottom: 6rem
        col
            height: 390px
           
.stairs
    background-size: cover
    position: relative
    .cover
        position: absolute
        top: 0
        bottom: 0
    .cover-left
        left: 0
        right: 70%
        background-color: white
    .cover-right
        left: 30%
        right: 0
        background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))
        margin-right: 20%
       
@media screen and (max-width: 425px )
    .stairs
        .cover-right
            margin-right: 0%

.stairs2
    background-position: 20% 40%

.support
    background-position: 57% 70%

.inter
    background-position: 57% 50%

.building
    background-position: 20% 40%

.consult
    background-position: 66% 40%

.custom
    background-position: 20% 37%

.ohubPhoto
    background-position: 20% 46%


        
.callflowsvg
    height: 260px
    width: auto

.arrowsvg
    height: 200px
    width: auto

.tabletsvg
    height: 300px
    width: auto


.ohubcol
    

.servicesTitleCon
    height: 450px

@media screen and (max-width: 768px )
    .servicesTitleCon
        height: 265px