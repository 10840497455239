.frontpage
    height: 100vh
    background-size: cover
    background-position: 24% 19%

.FpServicesTitle
    background-color: rgba(223, 223, 223, 1)

.frontpageBtn
    background-color: transparent 
    position: absolute
    bottom: 0%
    left: 50%
    padding-bottom: 30px
    border: none
   
.frontpageBtn:hover
    animation: bounce 0.7s infinite

@keyframes bounce
    0%
       
    100%
        transform: translateY(0)
    50%
        transform: translateY(-10px)




