.aboutus
    background-size: cover
    background-position: 24% 19%

.aboutus
    position: relative
    .darken
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-color: rgba(0, 0, 0, 0.7)
    .shimmer
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-color: rgba(221, 200, 214, 0.42)
    
.abouthead
    text-align: center 
    padding-top: 100px
    font-size: 100px   
    color: white
    
@media screen and (max-width: 768px ) 
    .abouthead
        font-size: 61px
    
.aboutext1

    font-size: 20px
    text-align: left
    padding: 50px
    color: white

.circlelane
    background: transparent 
    position: absolute
    border-radius: 50%
    width: 35px
    height: 35px
    border: solid 4px white
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)


.line1
    position: absolute
    border-left: 5px solid white
    height: calc(50% - 14px)
    bottom: 0%
    
    left: 50%
    transform: translate(-50%)

.line2
    position: absolute
    border-left: 5px solid white
    height: calc(50% - 15px)
    top: 0px
    left: 50%
    transform: translate(-50%)

.line3
    position: absolute
    border-left: 5px solid white
    height: calc(50% - 14px)
    bottom: 0%
    left: 50%
    transform: translate(-50%)
    
.circlelane2
    background: transparent 
    position: absolute
    border-radius: 50%
    width: 35px
    height: 35px
    border: solid 4px white
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.line4
    position: absolute
    border-left: 5px solid white
    height: calc(50% - 14px)
    top: 0px
    left: 50%
    transform: translate(-50%)

.circlelane3
    background: transparent 
    position: absolute
    border-radius: 50%
    width: 35px
    height: 35px
    border: solid 4px white
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.aboutext2
 
    font-size: 20px
    text-align: left 
    padding: 50px
    color: white 


.aboutext3
   
    font-size: 20px
    text-align: left
    padding: 50px
    color: white 


    

        



.aboutUsText
    padding-top: 5rem
    padding-bottom: 5rem
    font-size: 22px

.aboutusphoto
    overflow: hidden
    text-align: center
    margin-bottom: 200px


.vdphoto
    background-size: cover
    height: 390px
    width: 390px
    border-radius: 50%
    background-position: 3px -34px
    margin-top: 26%
    margin-right: 5%
  


.ctophoto
    background-size: cover
    height: 390px
    width: 390px
    border-radius: 50%
    background-position: 3px -34px
    margin-top: 26%

@media screen and (max-width: 768px )
    .vdphoto, .ctophoto
        height: 270px
        width: 270px
    

.phototextH
    text-align: center
    margin-top: 4%

.phototextC
    text-align: center
    margin-bottom: 10%
    
.page
    margin-top: 55px
