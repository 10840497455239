.header
    background-color: white
    background-color: #f6f6f681
    z-index: 1000
    width: 100%
    position: fixed
    top: 0
    box-shadow: 0px 0px 12px -6px black
    padding: 5px 5px
    padding-top: 10px
    backdrop-filter: blur(10px)
    @media screen and ( max-width: 992px )
        padding: 10px 10px 
        padding-top: 15px
.container-header
    //max-width: 1200px

// .hcomp
//     text-decoration: none

.menulink
    color: black
    font-size: 1.5rem 
    @media screen and ( max-width: 992px)
        font-size: 1rem

.menulink
    &:hover
        cursor: pointer
        text-decoration: underline
        text-decoration-thickness: 0.7px
        // Disable link style.
        &.noLink
            text-decoration: none
            cursor: default

.menulink1
    margin-bottom: 6px


        


    
        




.col-2
    padding: 14px 33px 16px 30px 
    font-weight: bold 
    a
        text-decoration: none
        color: black 
        font-size: 30px

.logo
    width: auto
    height: 40px
    // @media screen and ( max-width: 992px )
    //     height: 30px

.colpro
// .menu-item
//     font-size: 20px
//     padding-left: 1rem 
//     padding-bottom: 1rem  

// .bm-overlay
//     background: white !important
//     opacity: 0 !important
//     top: 0 !important
//     left: 0 !important
    
// .bm-menu-wrap
//     background: white !important
//     opacity: 1 !important
//     width: 200px !important
//     top: 137px
    

// .bm-burger-button
//     height: 30px !important
//     width: 30px !important
//     position: relative 
//     left: 91% !important
//     top: 35px !important
//     button
//         height: 30px !important
//         width: 30px !important
//         top: 50% !important
//         left: 50% !important
//         transform: translate(-50%, -50%)
    

.burgerbtn
    padding: 5px
    cursor: pointer
    width: 40px
    height: 40px
    @media screen and (min-width: 768px )
        width: 40px
        height: 40px


// @media screen and (min-width: 992px )
//     .burgerbtn
//         display: none

.nav-menu
    position: fixed
    background-color: white
    right: 0%
    top: 0%
    overflow: hidden
    transition: all 0.5s ease-in-out
    z-index: 1000

.nav-menu.active
    box-shadow: 0px 0px 12px -6px black

.nav-menu.active
    transition: all 0.5s ease-in-out
    transform: translateX(0%)

.nav-menu.hidden
    transition: all 0.5s ease-in-out
    transform: translateX(100%)

.menu-backdrop
    position: fixed
    background-color: black
    top: 0
    left: 0
    right: 0
    bottom: 0
    &.hidden
        display: none
        opacity: 0
        z-index: -1
    &.active
        opacity: 0.5
        z-index: 999



// @media screen and (min-width: 992px ) 
//     .nav-menu
//         display: none
    
.closebtn
    border: none
    background: none
    margin: 17px 26px 10px 187px
    svg
        width: 27px

@media screen and ( max-width: 992px)
    .closebtn 
        margin: 17px 26px 10px 103px
    
.navbar-toggle
    list-style-type: none
    padding: 19px 18px 11px 0px
    top: 5%
    a
        font-size: 20px

.nav-menu-items
    
    width: 294px
    height: 100vh

@media screen and ( max-width: 992px)
    .nav-menu-items
        width: 200px


.ulink
    font-size: 1.1rem
    margin-left: 10px
    margin-bottom: 6px

@media screen and ( max-width: 992px)
    .ulink
        font-size: 0.7rem



