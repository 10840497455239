.contactus
    background-color: #171717

    
    
.concu
    background-color: #171717
    width: 100vw
    
    
    
.contacthead
    
    color: white
    text-align: center
    font-size: 100px
    padding-top: 100px
    padding-bottom: 5rem

@media screen and (max-width: 768px ) 
    .contacthead
        font-size: 61px

.row11
   
    

.col111
    text-align: center
    padding-bottom: 5rem 
    color: white

.col112
    text-align: center 
    padding-bottom: 5rem 
    color: white

.col113
    text-align: center
    padding-bottom: 5rem 
    color: white

.footer
    background-color: white
    padding-top: 150px
   
.fotbtn
    border-radius: 15px
    border: 3px solid black
    width: 70px
    height: 70px
    background-color: transparent

.arrow
    border-top: 2px solid black
    border-left: 2px solid black
    width: 30px
    height: 30px
    transform: rotate(45deg)
    transform-origin: center center
    margin-left: auto
    margin-right: auto
    margin-top: 10px
   
.fotline
    border-top: 3px solid rgba(160, 26, 107, 1)
    width: 80vw
    

.rowfot
    padding-top: 7rem

.rowbot
    padding: 3rem
    font-size: 15px

.mail-link
    color: #d06fa9
    cursor: pointer