.product
    background-color: white

    .container
        padding-top: 10vh
        padding-bottom: 10vh
        @media ( min-width: 992px )
            padding-top: 200px
            padding-bottom: 100px

    .container
        position: relative

    .vl
        border-left: 5px solid rgba(160, 26, 107, 1)
        height: 700px
        position: absolute
        left: -20%

    .heading
        color: black
        text-align: center
        font-size: 100px
        padding-top: 100px

    .genesyslogo
        background-color: #3C3C3C
        color: white

        .container
            padding-top: 43px
            padding-bottom: 43px

    .genesyslogonew
        width: 300px

    .servicesTitleBackground
        background-color: rgba(229, 229, 229, 1)

    .servicesTitle2
        h2
            padding-bottom: 2rem
            font-size: 40px
        col
            height: 390px

    .servicestext2
        font-size: 22px

    .OHtitle
        h2
            padding-bottom: 2rem
            font-size: 40px
        p
            font-size: 22px

    .col1
        text-align: left
        font-size: 20px

    @media screen and (max-width:768px )
        .col1
            font-size: 16px

    .p-img
        height: 500px
        width: 100%
        background-size: cover
        background-position: 50% 50%

    .col3text
        text-align: center

        font-size: 100px

    @media screen and (max-width: 768px )
        .col3text
            font-size: 61px

    .conoh
        background-color: rgba(229, 229, 229, 1)
        padding: 15px

    .conoh2
        h2
            padding-bottom: 1rem

    @media screen and (max-width: 768px)
        .conoh2
            padding: 0vh

    .col32

        font-size: 20px
        text-align: left
        padding: 50px

    .col31
        padding: 15px

    .ohsvg

    .OH

    .col41
        text-align: left

        font-size: 20px
        padding: 50px

    .col52
        text-align: left
        padding: 50px

        font-size: 20px

    .fig2
        animation: blipp4 9s infinite

    @keyframes blipp4
        0%
            opacity: 0
        20%
            opacity: 0
        25%
            opacity: 1
        95%
            opacity: 1
        100%
            opacity: 0

    .fig1
        animation: blipp 9s infinite

    @keyframes blipp
        0%
            opacity: 0
        60%
            opacity: 0

        65%
            opacity: 1
        95%
            opacity: 1
        100%
            opacity: 0

    .fig3
        animation: blipp5 9s infinite

    @keyframes blipp5
        0%
            opacity: 0
        40%
            opacity: 0

        45%
            opacity: 1
        95%
            opacity: 1
        100%
            opacity: 0

    .fig3-btn
        opacity: 0
        animation: blipp6 9s infinite

    @keyframes blipp6
        0%
            opacity: 0
        75%
            opacity: 0
        80%
            opacity: 1
        95%
            opacity: 1
        100%
            opacity: 0

    .path1
        animation: blipp2 9s infinite

    @keyframes blipp2
        0%
            opacity: 0

        30%
            opacity: 0
        35%
            opacity: 1
        90%
            opacity: 1
        95%
            opacity: 1
        100%
            opacity: 0

    .path2
        animation: blipp3 9s infinite

    @keyframes blipp3
        0%
            opacity: 0
        40%
            opacity: 0

        45%
            opacity: 1
        95%
            opacity: 1
        100%
            opacity: 0

    .btn1
        animation: color 9s infinite

    @keyframes color
        0%
            fill: rgba(228, 228, 228, 1)

        50%
            fill: rgba(228, 228, 228, 1)
        55%
            fill: rgba(70, 180, 160, 1)
        95%
            fill: rgba(70, 180, 160, 1)
        100%
            fill: rgba(228, 228, 228, 1)

    @media screen and (max-width: 768px)
        .cubes
            padding: 0px 40px
            max-width: 600px

    @media screen and (max-width: 768px)
        .tablet
            width: 270px

    @media screen and (max-width: 768px)
        .comput
            padding: 0px 30px
            max-width: 600px

    .rowabout
        padding-bottom: 120px

.qoutecarousel
    height: 25rem
    background-color: black

.carousel
    .carousel-item
        height: 45rem
        font-size: 36px
        text-align: center
        @media screen and (max-width: 768)
            height: 16rem
            font-size: 15px
        @media screen and (max-width: 1025px)
            height: 20rem
            font-size: 16px

    .carousel-indicators li
        background-color: #ffffff 
        background-color: rgba(70,70,70,.25) 
    .carousel-indicators .active
        background-color: #444 

// Larger than iPad
.genesyslogoH2 // Larger than iPad
    margin: 0px
    text-align: center
    font-size: 3rem
    @media screen and (max-width: 768px) // Less than iPad.
        font-size: 1rem

.genesyslogoresponsive // Larger than iPad
    width: 400px
    @media screen and (max-width: 768px) // Less than iPad.
        width: 170px
        max-height: 120px
